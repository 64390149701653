<template>
  <v-virtual-scroll
      :items="items"
      height="800"
      item-height="64"
      v-if="items.length > 0"
  >

    <template v-slot:default="{ item }">

      <v-list-item :key="item.id" class="pt-5">
        <v-list-item-action>
          <v-icon v-if="item.type === 'task'">mdi-format-list-checks</v-icon>
          <v-icon v-else-if="item.type === 'wedding'">mdi-ring</v-icon>
          <v-icon v-else>mdi-calendar</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            <strong :style="'color: '+item.color">{{ getStringDate(item.start) }}</strong> {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>

        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-virtual-scroll>
  <NoResultSnippet
      v-else
      :text="noResultsText">
  </NoResultSnippet>
</template>

<script>

const NoResultSnippet = () => import("@/components/generalUI/NoResultSnippet");

export default {
  name: "Events",
  components: {
    NoResultSnippet
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    items: {
      type: Array,
      default() {
        return []
      }
    },
    noResultsText: String
  },
  mounted() {
  },
  methods: {
    getStringDate(date) {
      if (date !== null) {
        const newDate = new Date(date)
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return newDate.toLocaleDateString('de-DE', options)
      } else {
        return ""
      }
    },
  }
}
</script>
